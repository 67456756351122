<template>
  <section>
    <Backtoolbar
      bgcolor="white"
      title="Order History"
      customRoute="profile"
      hasDrawer="true"
      @open-drawer="$emit('open-drawer')"
    />
    <CentralLoader v-if="isLoading"></CentralLoader>
    <v-main v-else>
      <v-container>
        <OrderHistoryCardsWithSearch :orders="orders" />
      </v-container>
    </v-main>
  </section>
</template>

<script>
import Backtoolbar from '@/components/partials/BackToolbar';
import { mapActions, mapState } from 'vuex';
import CentralLoader from '@/components/Loaders/CentralLoader';
import OrderHistoryCardsWithSearch from '@/components/OrdersHistory/OrderHistoryCardsWithSearch';
export default {
  components: {
    OrderHistoryCardsWithSearch,
    Backtoolbar,
    CentralLoader,
  },
  methods: {
    ...mapActions('order', ['getOrders']),
  },
  computed: {
    ...mapState('order', {
      orders: (state) => state.list,
    }),
  },
  data: function () {
    return {
      isLoading: false,
      tabs: 0,
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.getOrders();
    this.isLoading = false;
  },
};
</script>

<style></style>
